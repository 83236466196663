.dashboard-container {
    display: flex;
    height: 100vh;
  
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
  
  .navbar {
    display: flex;
    flex-direction: column;
    flex: 0 0 200px;
    background-color: #f2f2f2;
    padding: 20px 15px;

    .logoContainer{
      width: 85%;
      margin: 0 auto 20px auto;
    }

    .logo{
      width: 100%;
    }
  
    @media (max-width: 768px) {
      flex: 0 0 auto;
    }
  }

  .profileContainer{
    display: flex;
    align-items: center;
    margin-top: auto;
    justify-content: space-between;
  }
  
  .profile-info {
    display: flex;
    align-items: center;
    margin-top: auto;
  }

  .settingsIcon{
    color: black;
    opacity: .8;
    transition: .2s;
  }

  .settingsIcon:hover{
    opacity: 1;
  }
  
  .profile-picture {
    width: 50px;
    height: auto;
    border-radius: 4px;
    margin-right: 10px;
  }
  
  .account-info {
    display: flex;
    flex-direction: column;
  }
  
  .account-name {
    font-weight: 600;
    font-size: 14px;
    margin: 0 0 2px 0;
    text-align: left;
  }
  
  .plan-name {
    font-size: 14px;
    margin: 0;
    opacity: 0.8;
    text-align: left;
  }
  
  .menu {
    list-style-type: none;
    padding: 0;
    margin: 0;
  
    li {
      margin-bottom: 12px;
      padding: 12px 12px;
      border-radius: 4px;
      transition: .2s;
  
      a {
        color: #333;
        text-decoration: none;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        transition: .2s;
  
        .menu-icon {
          margin-right: 10px;
        }
      }
    }

    li:hover{
      background-color: rgba(92, 136, 251, 0.2);
      cursor: pointer;
      
      a{
        color: #3459C1;
      }
    }
  
    li.active {
      background-color: rgba(92, 136, 251, 0.2);
    }
  
    li.active a {
      color: #3459C1;
    }
  }
  
  .dashboard {
    flex: 1;
    padding: 20px;
  
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  
  .dashboard-header {
    display: flex;
    flex-direction: row;
    gap: 25px;
    margin-bottom: 20px;
  
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  
  .search-bar {
    width: 100%;
    padding: 4px 8px;
    height: 29px;
    border: 1px solid #ccc;
    border-radius: 4px;
    display: flex;
    align-items: center;
  
    @media (max-width: 768px) {
      margin-bottom: 10px;
    }
  
    .search-icon {
      margin-right: 10px;
      width: 20px;
    }
  
    input {
      flex: 1;
      border: none;
      outline: none;
    }
  }
  
  .dashboard-buttons {
    display: flex;
    min-width: 380px;
  
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
    }
  
    button {
      margin-right: 10px;
  
      @media (max-width: 768px) {
        margin-bottom: 10px;
      }
    }
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
  
    th {
      padding: 10px;
      color: #74809C;
      font-weight: 600;
      text-align: left;
    }

    td {
      padding: 30px 10px;
      border-bottom: 1px solid #ccc;
    }
  
    .item-image {
      width: 40px;
      height: 40px;
      object-fit: cover;
    }
  }
  
  .links-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 999;

    .links-container {
        height: 100vh;
        width: 350px;
        margin-left: auto;
        padding-top: 30px;
        background-color: #fff;
        transform: translateX(0);
        transition: transform 0.3s ease-in-out;
      }
      
      .links-overlay.show .links-container {
        transform: translateX(100%);
      }
  
    .links-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 20px;
  
      h2 {
        margin: 0;
        font-size: 20px;
        font-weight: 600;
      }
  
      .close-btn {
        border: none;
        background-color: transparent;
        color: #3459C1;
        font-size: 14px;
        cursor: pointer;
      }
    }
  
    .links-list {
      max-height: calc(100vh - 200px);
      overflow-y: auto;
  
      .link-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;
        border-bottom: 1px solid #ccc;
  
        p {
          margin: 0;
          flex-grow: 1;
          font-size: 14px;
        }
  
        .remove-btn {
          border: none;
          background-color: transparent;
          color: #ff5151;
          font-size: 14px;
          cursor: pointer;
        }
      }
    }
  }
  