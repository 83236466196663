#login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  
    .logo {
      width: 260px;
      height: auto;
      margin-bottom: 20px;
    }
  
    .login-container {
      padding: 20px;
      width: 90%;
      max-width: 320px;
    }
  
    h2 {
      font-family: 'ArmaduraSolid', sans-serif !important;
      margin-top: 0;
      margin-bottom: 15px;
      font-size: 20px;
    }
  
    .form-group {
      margin-bottom: 10px;
    }
  
    label {
      display: block;
      font-weight: 600;
      margin-bottom: 5px;
      font-size: 14px;
    }
  
    .input {
      width: calc(100% - 25px);
      padding: 12px;
      border: 1px solid #ccc;
      outline: 1px solid transparent;
      border-radius: 4px;
      transition: 0.2s;
      margin-bottom: 10px;
    }
  
    .input:focus {
      border: solid 1px #3459C1;
      outline: solid 1px #3459C1;
    }
  
    .google-auth {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  
    .google-icon {
      margin-right: 10px;
    }
  
    .btnGroup {
      display: flex;
      flex-direction: row;
      gap: 10px;
    }
  
    p {
      text-align: center;
      font-size: 14px;
      opacity: 0.6;
      margin-top: 30px;
    }
  
    .btn {
      margin-bottom: 10px;
    }
  
    .password-input-container {
      position: relative;
    }
  
    .password-toggle {
      position: absolute;
      top: 42.5%;
      right: 10px;
      transform: translateY(-50%);
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      opacity: .8;
    }
  }
  