@import url('https://fonts.googleapis.com/css2?family=Arimo:wght@600;700&family=Inter:wght@300;400;500;600;700&display=swap');

@font-face {
  font-family: 'ArmaduraSolid';
  src: url('./Fonts/ArmaduraSolid.ttf') format('truetype');
}

body {
  font-family: "Inter", sans-serif;
  margin: 0;
  padding: 0;
  color: black;
  overflow-x: hidden;
  overflow-y: scroll;
  background-repeat: no-repeat;
  background-color: white;
}

html {
  scroll-behavior: smooth;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
p,
a,
input,
td,
th
 {
  font-family: "Inter", sans-serif !important;
  margin: 0;
}

.btn {
  font-family: "Inter", sans-serif !important;
  width: 100%;
  /* Updated */
  padding: 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;
}

.btn.primary {
  background-color: #3459C1;
  color: white;
}

.btn.secondary {
  background-color: rgba(92, 136, 251, 0.2);
  color: #3459C1;
}